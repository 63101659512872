﻿@import '_variables';
@import '_mixins';

.dropdown-filter,
.dropdown-checkbox,
.custom-dropdown {
  display: inline-block !important;

  @include custom-scrollbar;

  .dropdown,
  .dropup {
    .dropdown-menu {
      width: fit-content;
      min-width: 100%;
      padding: 0;
      border: 1px solid $active-state-color;

      &.show {
        display: flex;
        flex-direction: column;

        .apply {
          margin-top: 0.4rem;
          align-self: center;
        }
      }
    }

    &.show {
      .dropdown-toggle {
        border: 1px solid $active-state-color !important;

        > fa-icon {
          color: $active-state-color;
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }
      }
    }

    .dropdown-toggle {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &::after {
        display: none;
      }

      fa-icon {
        color: $primary-color;
      }

      &:disabled {
        background-color: $secondary-color;
        color: $secondary-color-10;

        fa-icon {
          color: $secondary-color-10;
        }
      }
    }

    .items {
      display: block;
      overflow-y: auto;
      max-height: 40vh;
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      padding: $input-btn-padding-y $input-btn-padding-y;

      .form-check {
        .form-check-input {
          cursor: pointer;

          &.color,
          &.material {
            &.MINTGREEN,
            &.deviceUnitColor-35 {
              background-color: $mintgreen;
            }

            &.BLACKGREY {
              background-color: $blackgrey;
            }

            &.PUREORANGE,
            &.deviceUnitColor-133 {
              background-color: $pureorange;
            }

            &.ALUMINIUM,
            &.deviceInsetMaterial-40 {
              background-color: $aluminium;
            }

            &.SIGNALRED,
            &.deviceUnitColor-27 {
              background-color: $signalred;
            }

            &.PUREWHITE,
            &.deviceUnitColor-7 {
              background-color: $purewhite;
            }

            &.STAINLESSSTEEL,
            &.deviceInsetMaterial-3 {
              background-color: $stainlesssteel;
            }

            &.IRONGREY,
            &.deviceInsetColor-57 {
              background-color: $irongrey;
            }

            &.BRASS,
            &.deviceInsetMaterial-13 {
              background-color: $brass;
            }

            &.GREYBEIGE,
            &.deviceInsetColor-58 {
              background-color: $greybeige;
            }

            &.GRAPHITBLACK,
            &.deviceInsetColor-32 {
              background-color: $graphitblack;
            }

            &.PCABS,
            &.deviceInsetMaterial-42 {
              background-color: $black;
            }
          }

          &.image {
            background-color: transparent;
            position: relative;

            img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              max-height: 100%;
              max-width: 100%;
            }
          }
        }

        .form-check-label {
          font-weight: bold;
        }
      }
    }

    &.show {
      .dropdown-toggle {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      padding: $input-btn-padding-y $input-btn-padding-y;
    }
  }

  .dropup {
    &.show {
      .dropdown-menu {
        margin-bottom: $dropdown-spacer !important;
      }
    }
  }
}

.dropdown {
  .btn-contextmenu-toggle {
    padding: 0.4rem;

    &::after {
      content: none;
    }
  }

  &.show > .btn-secondary.dropdown-toggle {
    color: $btn-down-color !important;
    border-color: $btn-down-color !important;
    background-color: white !important;
  }

  .contextmenu-content {
    padding: 0.2rem 0;

    &.parent-is-overflow-hidden {
      transform: translateX(-110px);
      margin-right: -110px !important;
      position: relative;

      &.show {
        display: flex;
        flex-direction: column;
      }
    }

    button,
    a,
    label {
      &.btn-contextmenu {
        background-color: transparent;
        border: none;
        padding: 0.5rem 1rem;
        text-transform: none;
        text-align: left;
        white-space: nowrap;
        width: 100%;

        &:hover,
        &:focus,
        .active {
          background-color: rgba($secondary-color-10, 0.5);
        }
      }
    }
  }
}
