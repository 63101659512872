﻿.product-chooser {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    [id|="product-details"] {
        overflow: hidden;
        width: 100%;
        transform: translateY(-4rem);
        border: solid 1px $secondary-color-10;
        box-shadow: 0px 0px 5px 2px $secondary-color-10;
        background: $white;
        z-index: ($z-index-overlay + 1);

        [id|="product-details"] {
            // for recursive productchoosers inside productDetails
            width: 90%;
            margin: 0 auto;
        }

        .product-detail {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 2rem;
            background: rgba($secondary-color, 0);
        }

        footer {
            border-top: none;
            background-color: white;
            padding: 10px 0;
            text-align: right;
        }
    }

    .pageSize-option {
        color: $black;
        background: transparent;
        border: 0;
        padding: 0 0;
        margin: 0 0.2rem;
        position: relative;
        height: 2.25rem;
        width: 2.25rem;

        &:focus {
            outline: none;
        }

        &::before {
            content: "";
            position: absolute;
            display: inline-flex;
            border: 2px solid $primary-color;
            border-radius: 50%;
            color: $primary-color;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &.active {
            &::before {
                border-color: black;
                color: black;
            }
        }
    }
}
