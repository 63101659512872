.switch {
    display: inline-flex;
    align-items: center;

    input {
        display: none;

        &:checked + .slider {
            background-color: $primary-color;
            &::before {
                transform: translateX(80%);
            }
        }

        &:focus + .slider {
            box-shadow: 0 0 1px $primary-color;
        }

        &:disabled {
            & ~ * {
                text-decoration: line-through;
                color: $secondary-color-10;
            }

            & + .slider {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }

    .slider {
        cursor: pointer;
        background-color: #ccc;
        transition: 0.4s;

        $slider-height: 2rem;

        position: relative;
        display: inline-block;
        min-width: calc(#{$slider-height} * 1.6);
        height: $slider-height;

        &::before {
            position: absolute;
            content: '';
            height: calc(#{$slider-height} * 0.8);
            min-width: calc(#{$slider-height} * 0.8);
            left: 5%;
            bottom: 10%;
            background-color: $secondary-color-10;
            transition: 0.4s;
        }

        &.round {
            border-radius: 34px;

            &::before {
                border-radius: 50%;
            }
        }
    }
}
