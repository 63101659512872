﻿@import "_variables";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@mixin custom-small-button-text-mixin {
    background: transparent;
    padding: 0;
    vertical-align: middle;
    font-weight: 700;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    text-align: left;

    fa-icon {
        color: $primary-color;
        margin-right: 0.25rem;
    }

    &:hover {
        color: $primary-color;
    }
}

@mixin custom-scrollbar() {
    ::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-height;
        scroll-behavior: smooth;
        -webkit-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        &:hover {
            background: $secondary-color-10;
        }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $scrollbar-color;
        -webkit-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $scrollbar-hover-color;
    }
}
