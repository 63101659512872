﻿// UFS colors

// Deviceunits
$mintgreen: #006f3d;
$blackgrey: #2f3234;
$pureorange: #e25303;
$aluminium: #a1a1a0;
$signalred: #9b2423;
$purewhite: #f1ece1;
$stainlesssteel: #c0c0c0;

// Deviceinsets
$irongrey: #52595d;
$brass: #d4af37;
$greybeige: #a48f7a;
$graphitblack: #27292b;
