@mixin shared-return-btn-styles {
    fa-icon {
        color: $primary-color;
    }

    &:hover {
        text-decoration: underline;
        color: $primary-color;

        * {
            color: $primary-color;
        }
    }
}

.btn {
    padding: 1.071rem 1.429rem;
    cursor: pointer;
}

.btn,
.btn-sm {
    border: none;
    background-repeat: no-repeat;

    fa-icon {
        margin-right: 0.5rem;
    }

    &.btn-transparent {
        background: transparent;
        border: none;
    }

    &.btn-secondary {
        color: $primary-color;
        background-color: $white;
        border: 2px solid $primary-color;
        transition: 0.5s;
        border-radius: $custom-btn-border-radius;

        &:visited,
        &:focus:not(:active) {
            color: $primary-color !important;
            background: $btn-text-primary-color;
        }

        &:active:not(.dropdown-toggle) {
            color: $btn-down-color !important;
            border: 2px solid $btn-down-color !important;
            background-color: white !important;
        }

        &:hover:not(.dropdown-toggle):not(:active):not(:disabled):not([disabled]) {
            text-decoration: underline !important;
        }

        &:disabled,
        &[disabled] {
            color: $btn-text-disabled-color !important;
            background: $btn-disabled-color !important;
            border: $btn-disabled-color;

            fa-icon {
                color: white;
            }
        }

        &.dropdown-toggle {
            &:active,
            &:hover {
                background-color: white !important;
                border-color: $btn-down-color !important;
                color: $btn-down-color !important;
            }
        }
    }

    &.btn-fa {
        background: transparent;
        border: none;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        fa-icon {
            color: $primary-color;
        }

        &.text-white {
            fa-icon {
                color: #fff;
            }
        }

        &.text-black {
            fa-icon {
                color: #000;
            }
        }

        &:hover {
            color: $primary-color;
        }

        &:disabled,
        &[disabled] {
            color: $btn-disabled-color !important;
            pointer-events: none;

            fa-icon {
                color: $btn-disabled-color;
            }
        }
    }

    &.btn-success {
        color: $btn-text-primary-color !important;
        background: $btn-primary-color;
        border: none !important;
        box-shadow: none;
        transition: background-color box-shadow 0.5s;
        border-radius: $custom-btn-border-radius;

        &:focus,
        &:hover,
        &:active,
        &:visited {
            box-shadow: $btn-success-box-shadow;
        }

        &:active {
            color: $btn-text-down-color !important;
            background: $btn-down-color !important;
        }

        &:visited,
        &:focus:not(:active) {
            color: $btn-text-primary-color !important;
            background: $primary-color;
        }

        &:hover:not(:active):not(:disabled):not([disabled]) {
            box-shadow: $btn-success-box-shadow;
        }

        &:disabled,
        &[disabled] {
            color: $btn-text-disabled-color !important;
            background: $btn-disabled-color !important;

            fa-icon {
                color: white;
            }
        }
    }

    &.btn-step-back,
    &.btn-return-to-project,
    &.btn-return-to-dashboard {
        @include shared-return-btn-styles;
    }

    &.btn-step-back {
        background: transparent;
        border: none;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-more-details-small {
        @include custom-small-button-text-mixin;

        fa-icon {
            @extend .btn-color-orange;
        }
    }

    &.icon-right {
        display: flex;
        flex-direction: row-reverse;

        fa-icon {
            margin-left: 0.5rem !important;
            margin-right: 0 !important;
        }
    }

    &.icon-center {
        fa-icon {
            margin: 0 !important;
        }
    }

    &.btn-return-to-project {
        position: absolute;
        left: 0;
        top: 50px;
        padding: 2rem;
        z-index: 1020;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &.btn-return-to-dashboard {
        padding: 0 !important;
    }
}

.btn-link {
    background: none;
    border: none;

    &:hover {
        text-decoration: none;
    }
}

.btn-color-orange {
    color: $btn-primary-color;
    border-color: $btn-primary-color;
    border: none !important;
    box-shadow: none !important;
    transition: color 0.5s;

    &:focus,
    &:hover,
    &:active,
    &:visited {
        box-shadow: none !important;
    }

    &:active {
        color: $btn-down-color !important;
        border-color: $btn-down-color !important;
    }

    &:visited,
    &:focus:not(:active) {
        color: $btn-primary-color;
        border-color: $btn-primary-color;
    }

    &:hover:not(:active):not(:disabled):not([disabled]) {
        color: $btn-hover-color !important;
        border-color: $btn-hover-color !important;
    }

    &:disabled,
    &[disabled] {
        color: $btn-disabled-color !important;
        border-color: $btn-disabled-color !important;
    }

    fa-icon {
        color: $btn-primary-color;
    }
}

.btn-blue {
    background-color: $white;
    border: 1px solid $obo-blue;
    width: 100%;

    &:hover {
        background-color: $obo-blue-lighter;
        border-color: $obo-blue-lighter;
    }

    &.active {
        background-color: $obo-blue-light;
        border-color: $obo-blue-light;
    }
}

.btn:focus {
    outline-color: $primary-color !important;
}

@include media-breakpoint-down(md) {
    .btn-container {
        flex-direction: column-reverse;

        .btn:not(.btn-fa) {
            justify-content: center;
            margin: 0 0 0.5rem 0 !important;
        }
    }

    div {
        .btn-step-back,
        .btn-success,
        .btn-secondary {
            width: 100%;
        }

        &.choose-overlay {
            .btn-success {
                margin: 0 0.5rem !important;
            }
        }
    }
}

.btn-pin {
    width: auto;
    margin-left: 10px;

    &.pinned {
        color: $primary-color;
        border: none;
    }
}

.btn-more-details {
    white-space: nowrap;
    transition: 0.2s all;
    padding: 5px;
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 20px;
    color: $obo-blue-dark;

    &:hover {
        border: 1px solid $obo-blue-dark;
        background-color: $obo-blue-lighter;
    }

    &.btn-small {
        width: 36px;
        height: 36px;
        line-height: normal;

        fa-icon {
            margin-right: 0 !important;
        }
    }

    &.pos-absolute {
        right: 0;
        position: absolute;
    }
}

.btn-admin-cache {
    margin-left: 1rem;
    max-height: 50px;
    display: flex;
    align-items: center;
    max-width: 250px;
    border-width: 1px !important;
}
