﻿.hide-input {
    // hide input. Needed to fix the chrome scroll-to-buttom issue
    position: absolute;
    left: -10px;
    visibility: hidden;
}

.tiles {
    & > div {
        padding: 2rem 1rem 1rem 1rem;
        cursor: pointer;
    }

    .tile {
        padding: 0;
        cursor: pointer;
        width: 100%;
        height: 15.29rem;

        .tileCaption {
            font-weight: $font-weight-bold;
            margin-top: 2rem;
            line-height: 1rem;
        }

        &.active,
        &:hover {
            span {
                color: $primary-color;
            }
        }

        a,
        button {
            img {
                max-height: calc(100% - 2rem);
            }

            span {
                word-break: break-all;
            }
        }

        .tile-image {
            max-height: calc(100% - 2rem);
            max-width: 100%;
            flex: 0 0 auto;
            object-fit: scale-down;
        }
    }
}

.entry-tiles {
    .entry-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: solid 1px transparent;
        background-color: transparent;
        position: relative;
        margin-bottom: 1rem;

        .entry-image {
            position: relative;
            flex-grow: 100;
            width: 100%;
            position: relative;
            min-height: 15rem;
            display: flex;
            justify-content: center;

            .img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                opacity: 0;
                z-index: 1;
                display: block;
                margin: 0 auto;
                max-height: 600px;
                transition: all 0.5s;
            }
        }

        &.image-loaded {
            .entry-image {
                min-height: 0;

                img {
                    opacity: 1;
                }
            }
        }

        .choose-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: none;
            z-index: 2;
            backdrop-filter: blur(2px);
        }

        &.clickable {
            [role="button"] {
                cursor: pointer;
            }

            .card-title {
                &:hover {
                    color: $primary-color;
                }
            }

            .entry-image {
                &:hover {
                    .choose-overlay {
                        display: flex;
                    }
                }
            }
        }
    }
}

.product-tiles {
    .product-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: solid 1px transparent;
        background-color: transparent;
        position: relative;
        margin-bottom: 1rem;

        > .card {
            border: solid 2px transparent;
        }

        .product-image {
            margin-bottom: 1rem;
            pointer-events: none;
        }

        .card-title {
            pointer-events: none;
        }

        .product-image {
            position: relative;
            flex-grow: 100;
            width: 100%;
            position: relative;
            min-height: 15rem;
            display: flex;
            justify-content: center;

            .image-loading {
                width: 100%;
                height: 100%;
                opacity: 1;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;

                .spinner {
                    z-index: ($z-index-overlay + 5);
                }
            }

            .img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                opacity: 0;
                z-index: 1;
                display: block;
                margin: 0 auto;
                max-height: 600px;
            }

            .image-loading,
            img {
                transition: all 0.5s;
            }
        }

        .inactive-indicator {
            position: absolute;
            align-self: center;
            z-index: 2;
            bottom: 4rem;
            padding: 5px 15px;
            width: 166px;
            background-color: $alert-danger-background-color;
        }

        &.image-loaded {
            .product-image {
                min-height: 0;

                .image-loading {
                    opacity: 0;
                    z-index: -1;
                }

                img {
                    opacity: 1;
                }
            }
        }

        .choose-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: none;
            z-index: 2;
            backdrop-filter: blur(2px);
        }

        &.clickable {
            [role="button"] {
                cursor: pointer;
            }

            .product-name,
            .card-title {
                pointer-events: visible;
                &:hover {
                    color: $primary-color;
                }
            }

            .product-image {
                pointer-events: visible;
                &:hover {
                    .choose-overlay {
                        display: flex;
                    }
                }
            }
        }

        &.is-inactive {
            img {
                opacity: 0.5 !important;
            }
        }

        &.show-marker {
            &:after {
                bottom: 2.9rem;
                z-index: ($z-index-overlay + 2);
                opacity: 1;
                transition-delay: 0;
            }
        }

        .indicators {
            position: absolute;
            top: 5px;
            left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 0.2rem;
            color: $secondary-color-50;

            i {
                margin: 0.2rem auto;
            }
        }

        .amount-controls {
            display: flex;
            flex: 0 0 auto;
            justify-content: space-between;
            margin-top: 1rem;

            > div,
            form {
                width: 100%;
                display: flex;
                align-items: center;

                .currentValue-input {
                    display: inline-flex;
                    min-width: 5rem;
                    text-align: center;
                }
            }
        }

        &.selected {
            > .card {
                border: 2px solid $primary-color;
            }
        }
    }
}

.ribbon-tile {
    position: relative;
    margin-bottom: 30px;

    .ribbon {
        position: absolute;
        right: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 75px;
        height: 75px;
        text-align: right;

        span {
            font-size: 10px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 100px;
            display: none;
            background: $primary-color;
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px;
            right: -21px;

            &::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid $primary-color;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid $primary-color;
            }

            &::after {
                content: "";
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid $primary-color;
                border-bottom: 3px solid transparent;
                border-top: 3px solid $primary-color;
            }
        }
    }

    input {
        &:checked ~ * {
            color: $primary-color;
        }

        &:checked ~ .ribbon {
            span {
                display: block;
            }
        }

        &:disabled ~ * {
            opacity: 0.2;
        }
    }
}
