﻿.product-table,
.expandable-table,
.project-table {
    .table-image {
        >img{
            max-width: 80px;
        }
    }

    width: 100%;

    &:not(:last-of-type) {
        margin-bottom: 0;
    }

    & > :not(caption) > * > * {
        box-shadow: none;
    }

    & > tbody > tr,
    & > tr {
        & > th {
            font-weight: normal;
            background: $secondary-color-10;
        }

        & > td {
            background: $white;
        }

        & > th,
        & > td {
            vertical-align: middle;
            border: 0.5px solid $secondary-dark-grey;
            padding: 10px !important;
        }
    }
}

.product-table {
    table-layout: fixed;

    & > tbody > tr,
    & > tr {
        & > th {
            width: 40%;
            word-break: break-word;
        }
    }
}

.expandable-table {
    table-layout: auto;
    margin-left: -2px;
    width: calc(100% + 4px);

    & > tbody > tr,
    & > tr {
        .rowExpandToggle {
            transform: rotate(90deg);
        }

        &.expanded {
            .rowExpandToggle {
                transform: rotate(-90deg);
            }

            & + tr td {
                &[colspan] {
                    background: inherit;
                    border: 1px solid $secondary-color-10;
                    position: relative;
                    z-index: 2;

                    > *:first-child {
                        margin: 2rem 0;
                    }

                    &::after {
                        transition: all 0.25s;
                        transition-delay: 0.25s;
                        position: absolute;
                        top: 0;
                        content: '';
                        z-index: 1;
                        right: 50%;
                        transform: translateX(0%) translateY(-50%)
                            rotate(-45deg);
                        width: 30px;
                        height: 30px;
                        border-top: 1px solid $secondary-color-10;
                        border-right: 1px solid $secondary-color-10;
                        border-left: 1px solid transparent;
                        border-bottom: 1px solid transparent;
                        background-color: $white;
                    }
                }
            }
        }
    }
}
