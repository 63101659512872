﻿@import "variables";

.dialog {
    padding: 2rem 2rem 3rem !important;
    background-color: $white;

    .dialog-header {
        margin: 0 0 1rem;
        display: flex;
        justify-content: flex-end;
    }

    .dialog-inner {
        padding: 0 1rem;

        h2 {
            margin: 0 0 1rem;
        }
    }

    .dialog-footer {
        border: none;
        margin: 2rem 1rem 0;
    }
}

.confirm-dialog {
    .confirmation {
        flex-direction: column;
        background: $white;

        .confirm-buttons,
        .confirm-label {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
        }
        .confirm-buttons {
            padding-bottom: 1rem;
            button {
                display: flex;
            }
        }
    }
}
