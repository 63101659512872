﻿.carousel {
    overflow-x: hidden;

    .carousel-inner {
        overflow: visible;
        position: relative;
        padding: 1.5rem 1rem;

        .carousel-item {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 300px;
            overflow: hidden;

            &.active {
                display: block;
            }

            img {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .carousel-indicators {
        position: static;
        margin: 1rem 0 0 0;
        padding: 0;
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
