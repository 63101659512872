﻿@use "sass:math";
@import "_variables";

$track-color: $obo-blue-lighter !default;
$thumb-color: $obo-blue !default;

$thumb-radius: 8px !default;
$thumb-height: 16px !default;
$thumb-width: 16px !default;
$thumb-shadow-size: 0px !default;
$thumb-shadow-blur: 0px !default;
$thumb-shadow-color: $obo-blue-lighter !default;
$thumb-border-width: 0 !default;
$thumb-border-color: $primary-color !default;

$track-width: 100% !default;
$track-height: 6px !default;
$track-shadow-size: 0 !default;
$track-shadow-blur: 0 !default;
$track-shadow-color: $obo-blue-lighter !default;
$track-border-width: 0 !default;
$track-border-color: $obo-blue-lighter !default;

$track-radius: 0px !default;
$contrast: 0% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
    box-shadow:
        $shadow-size $shadow-size $shadow-blur $shadow-color,
        0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
    cursor: default;
    height: $track-height;
    transition: all 0.2s ease;
    width: $track-width;
}

@mixin thumb {
    @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
    background: $thumb-color;
    border: $thumb-border-width solid $thumb-border-color;
    border-radius: $thumb-radius;
    box-sizing: border-box;
    cursor: default;
    height: $thumb-height;
    width: $thumb-width;
}

input[type="range"].form-range {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    margin: math.div($thumb-height, 2) 0;
    width: $track-width;

    &::-moz-focus-outer {
        border: 0;
    }

    &:focus {
        outline: 0;

        &::-webkit-slider-runnable-track {
            background: lighten($track-color, $contrast);
        }

        &::-ms-fill-lower {
            background: $track-color;
        }

        &::-ms-fill-upper {
            background: lighten($track-color, $contrast);
        }
    }

    &::-webkit-slider-runnable-track {
        @include track;
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
        @include thumb;
        -webkit-appearance: none;
        margin-top: (math.div((-$track-border-width * 2 + $track-height), 2) - math.div($thumb-height, 2));
    }

    &::-moz-range-track {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        @include track;
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius;
        height: math.div($track-height, 2);
    }

    &::-moz-range-thumb {
        @include thumb;
    }

    &::-ms-track {
        @include track;
        background: transparent;
        border-color: transparent;
        border-width: math.div($thumb-height, 2) 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $ie-bottom-track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: ($track-radius * 2);
    }

    &::-ms-fill-upper {
        @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: ($track-radius * 2);
    }

    &::-ms-thumb {
        @include thumb;
        margin-top: math.div($track-height, 4);
    }

    &:disabled {
        &::-webkit-slider-thumb,
        &::-moz-range-thumb,
        &::-ms-thumb,
        &::-webkit-slider-runnable-track,
        &::-ms-fill-lower,
        &::-ms-fill-upper {
            cursor: not-allowed;
        }
    }
}
