.nav {
  &.nav-tabs {
    border-bottom: none;

    .nav-item {
      .nav-link {
        background-color: $background-color;
        border-width: 0 0 2px 0;
        border-color: $secondary-color-30;
        color: $secondary-color-50;

        &.active {
          border-width: 2px 2px 0 2px;
          border-color: $secondary-color-30;
          color: black;
          font-weight: bold;
        }

        &:hover {
          color: black;
        }
      }
    }
  }
}

.tab-item {
  text-decoration: none;
  transition: all 0.1s linear;
  border-bottom: 0.33rem solid transparent !important;
  color: $black !important;
  padding: 0 1rem 0.2rem 0;
  margin: 0 0.3rem 0 0;
  
  &:hover {
    color: $primary-color !important;
  }

  &.active {
    border-bottom: 0.2rem solid $primary-color !important;
    color: $primary-color !important;
  }
}
