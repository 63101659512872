﻿@import 'bootstrap/scss/mixins/breakpoints';
@import 'variables';

.alert-icon {
    display: block;
    font-size: 30px;
    padding-right: 1rem;
}

.alerts {
    margin-top: $header-height;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(lg) {
        width: 50%;
    }

    @include media-breakpoint-down(xl) {
        width: 75%;
    }

    @include media-breakpoint-down(lg) {
        width: 80%;
    }

    @include media-breakpoint-down(md) {
        width: 90%;
    }

    z-index: $zindex-tooltip;

    p {
        width: 100%;
    }
}

.alert {
    &:not(.infobox) {
        z-index: $zindex-alert;
    }
    margin-top: 1rem;
    font-size: $custom-alert-font-size;
    line-height: $custom-alert-line-height;
    min-height: 56px;
    align-items: center;
    border: 0;
    color: $black;

    button.close {
        height: 100%;
    }

    .alert-header {
        width: 100%;
        display: flex;
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        align-items: center;
        justify-content: space-between;

        &.expandable {
            padding-left: 0.75rem;
        }
    }

    .alert-body {
        padding: 0 1.25rem 0.5rem;
    }

    @include media-breakpoint-down(lg) {
        .alert-icon {
            font-size: 20px;
        }
    }

    &.alert-warning {
        background-color: $alert-warning-background-color;

        .fa-icon {
            color: $alert-warning-text-color;
            @extend .alert-icon;
        }
    }

    &.alert-success {
        background-color: $alert-success-background-color;

        .fa-icon {
            color: $alert-success-text-color;
            @extend .alert-icon;
        }
    }

    &.alert-danger {
        background-color: $alert-danger-background-color;

        .fa-icon {
            color: $alert-danger-text-color;
            @extend .alert-icon;
        }
    }

    &.alert-info {
        background-color: $alert-info-background-color;

        .fa-icon {
            color: $alert-info-text-color;
            @extend .alert-icon;
        }
    }

    @include media-breakpoint-down(lg) {
        font-size: 1rem;
    }

    p {
        margin-bottom: 0;
    }

    .icon-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
