﻿//arialUniCode Font
$arialUniCode-font-path: "../styles/fonts/arialUnicode" !default;
//nimbusSansL Font
$nimbusSansL-font-path: "../styles/fonts/nimbusSansL" !default;

@font-face {
    font-family: "NimbusSansL";
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLBoldCondensed_normal_condensed.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLBoldCondensed_normal_condensed.woff') format('woff');
}
@font-face {
    font-family: "NimbusSansL";
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLLight_normal_normal.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLLight_normal_normal.woff') format('woff');
}
@font-face {
    font-family: "NimbusSansL";
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLRegular_normal_normal.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLRegular_normal_normal.woff') format('woff');
}
@font-face {
    font-family: "NimbusSansL";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLBold_normal_normal.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLBold_normal_normal.woff') format('woff');
}
@font-face {
    font-family: "NimbusSansL";
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLBoldItalic_italic_normal.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLBoldItalic_italic_normal.woff') format('woff');
}
@font-face {
    font-family: "NimbusSansL";
    font-weight: 300;
    font-style: normal;
    font-stretch: condensed;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLLightCondensed_normal_condensed.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLLightCondensed_normal_condensed.woff') format('woff');
}
@font-face {
    font-family: "NimbusSansL";
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$nimbusSansL-font-path}/NimbusSansLRegularItalic_italic_normal.woff2') format('woff2'), url('#{$nimbusSansL-font-path}/NimbusSansLRegularItalic_italic_normal.woff') format('woff');
}
@font-face {
    font-family: "ArialUnicode";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$arialUniCode-font-path}/ArialUnicodeBold_normal_normal.woff2') format('woff2'), url('#{$arialUniCode-font-path}/ArialUnicodeBold_normal_normal.woff') format('woff');
}
@font-face {
    font-family: "ArialUnicode";
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('#{$arialUniCode-font-path}/ArialUnicodeRegular_normal_normal.woff2') format('woff2'), url('#{$arialUniCode-font-path}/ArialUnicodeRegular_normal_normal.woff') format('woff');
}

.icon-checkmark {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjx0aXRsZT5jaGVja21hcms8L3RpdGxlPjxwYXRoIGZpbGw9IiNmMzljMDAiIGQ9Ik0wLjYsMjIuNyAxMiwzMC42IDEyLDMwLjYgMTYuMiwzMy40IDM1LjQsNS40IDMxLjMsMi42IDE0LjksMjYuNCAzLjQsMTguNSB6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
}

.company-logo {
    background-image: url("/assets/img/logo/brand.png");
    background-repeat: no-repeat;
    width: 195px;
    background-size: contain;
}
