.inline-editor {
    $inline-edit-padding-x: 0.2rem;
    $inline-editor-height: 2rem;
    min-width: 0;

    .read-mode {
        height: $inline-editor-height;
        border: 2px solid $background-color;

        .inline-btn-edit {
            opacity: 0;
            width: 0;
            padding: 0;
            margin: 0;
        }

        &:hover {
            border: 2px solid $secondary-color-10;
            transition: border 0.5s ease-in;

            .inline-btn-edit {
                opacity: 1;
                flex: 0 0 auto;
                transition: opacity 0.5s ease-in;
                width: auto;
                padding: inherit;
                margin: 0 0.25rem;
            }
        }

        .input-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            overflow: hidden;
            margin-bottom: 0;
        }
    }

    .edit-mode {
        .inline-input {
            text-align: left;

            &::-ms-clear {
                display: none;
            }
        }
    }

    &:not(.no-editmode-padding) {
        .edit-mode {
            height: $inline-editor-height;

            .inline-input {
                padding: 0.1rem $inline-edit-padding-x;
            }
        }
    }
}
