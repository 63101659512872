@import "variables";

.banner {
  $banner-bg-color: $primary-color;
  position: absolute;
  right: -10px;
  top: 10px;
  background-color: $banner-bg-color !important;
  color: $white !important;
  padding: 0 0.5rem 0 0.5rem !important;
  box-shadow: rgba(33, 35, 38, 1) 0 10px 10px -10px;
  z-index: 3;

  &::after {
    content: "";
    width: 0;
    height: 0;
    -webkit-transform: rotate(360deg);
    border-style: solid;
    border-width: 0.5rem 0.7rem 0 0;
    border-color: darken($banner-bg-color, 10%) transparent transparent transparent;
    position: absolute;
    top: 100%;
    right: 0;
  }

  &.info{
    background-color: $obo-blue-flag !important;

    &::after {
      border-color: darken($obo-blue-flag, 10%) transparent transparent transparent;
    }
  }

  &.warning {
    background-color: $red !important;

    &::after {
      border-color: darken($red, 10%) transparent transparent transparent;
    }
  }

  &.small {
    top: 5px;
  }
}

.product-card-banner {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 12px;
  color: $white;
  height: 26px;
  white-space: nowrap;

  &.info {
    background-color: $obo-blue-flag;
  }

  &.warning {
    background-color: $alert-warning-text-color;
  }
}
