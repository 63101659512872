@import "variables";

.modal {
  position: static !important;
  display: block !important;
  background-color: $white;

  .modal-header {
    justify-content: space-between;
  }

  .modal-header,
  .modal-body {
    background-color: $white !important;
  }

  .modal-body {
    overflow-x: hidden !important;
    flex-direction: column;

    .form-control {
      border-color: $input-border-color;

      &:focus {
        border-color: $input-focus-border-color;
      }
    }
  }

  .modal-footer {
    background-color: $obo-blue-lighter !important;
  }

  .modal-body {
    .infobox {
      display: block !important;
      margin-bottom: 0;

      p {
        font-size: 1rem;
      }
    }
  }
}
