﻿.card {
    &.card-sm {
        flex-direction: row;

        .card-body {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        img {
            max-height: 8rem;
        }
    }

    &.card-no-border {
        border: none;
    }

    .card-border-top {
        border-top: 1px solid $secondary-color-10;
    }

    &.card-border-bottom {
        &:not(:last-child) {
            border-bottom: 0.5px solid $secondary-color-10;
        }
    }

    &.card-no-shadow {
        box-shadow: none;
    }

    display: flex;
    flex-grow: 1;
    padding: 0;
    margin-bottom: 1rem;
    box-shadow: 0px 1px 1px 0px $secondary-color-50, 0.5px 0px 1px $secondary-color-30, -0.5px 0px 1px $secondary-color-30;

    figure {
        margin: 0;
    }

    .card-title {
        text-transform: none;
        text-align: left;

        &.entry-title {
            margin-bottom: 0 !important;
            cursor: pointer;
        }
    }

    .card-body {
        padding: 1rem;
    }

    .card-header {
        background-color: $white;
    }

    .card-footer {
        &.card-footer-white {
            background-color: $secondary-color;
        }

        .card-footer-expand {
            @include media-breakpoint-up(sm) {
                hyphens: auto;
                background-color: $white;
                margin-left: -1rem;
                padding: 0.5rem 1rem;
            }
        }

        padding: 0.75rem 1rem;
        .cbx {
            display: inline-flex !important;
            padding: 1rem;
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
            opacity: 0.7;
            border: solid 0.5px $secondary-color-30;
        }

        a {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &.active {
        .card-footer {
            .checkmark {
                @extend .icon-checkmark;
                background-size: 1.3rem;
                background-position: center center;
            }
        }
    }

    &.card-border-left {
        border-left: 3px solid;
    }
}

// Background position overrides for configurator images
.card-background-image {
    &.underfloor {
        background-position-x: right;
    }

    &.filling {
        background-position-x: right;
    }

    &.grounding {
        background-position-y: 90%;
    }

    &.incendiary {
        background-position-x: 40%;
    }

    &.overvoltage {
        background-position-x: center;
    }

    &.photovoltaic {
        background-position-x: center;
    }

    &.egress {
        background-position-x: left;
    }
}

.card-shadow {
    transition: all 0.3s ease-in;
    box-shadow: 0px 4px 4px rgba(207, 218, 229, 0.3);

    &:hover {
        box-shadow: 0px 3px 3px rgba(207, 218, 229, 0.15);
    }
}

.card-grow {
    transition: all 0.3s ease-in;

    &:hover {
        transform: scale(1.035);
    }
}
