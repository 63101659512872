@import "variables";
$form-control-height: calc(2.9rem + 2px);

form {
    .required {
        & > label:first-child:after {
            content: "*";
        }
    }

    .form-label {
        text-wrap: nowrap;
        margin-bottom: 0.5rem;
    }

    label {
        img {
            pointer-events: none; // IE11 hack. IE11 does not propagate click to input inside the label when an image element is clicked
        }
    }
}

.input-help {
    cursor: pointer;
    margin: 0 0 0 0.4rem;
}

input {
    &:focus {
        outline: none;
        border-color: $input-focus-border-color;
    }

    &[type="text"],
    &[type="number"],
    &[type="password"] {
        /*removes the top shadow iOS sarari adds to input elements */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &[shdToggle] {
            ~ .password-toggle-btn {
                position: absolute;
                display: flex;
                align-items: center;
                right: 1rem;
                top: calc(100% - #{$form-control-height});
                bottom: 0;
            }
        }
    }
}

.input-group {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex-flow: row;

    input {
        border: solid $border-width $white;
        background-color: $white;
        border-right: 0;
        text-align: right;
        flex-grow: 1;
        max-width: 100%;

        + .input-group-append {
            border-left: 0;

            &.input-group-append-border {
                border-color: $input-border-color;
            }
        }

        &:focus {
            border-color: $input-focus-border-color;

            + .input-group-append {
                border-color: $input-focus-border-color;
            }
        }

        &:disabled {
            border-color: $input-disabled-bg;
        }

        &.ng-invalid:not(.ng-pristine) {
            border-right: 0;

            + .input-group-append {
                border-color: $red;

                &.input-group-append-border {
                    border-color: $red;
                }
            }
        }
    }

    .input-group-append,
    .input-group-prepend {
        background-color: $white;
        border: solid $border-width $white;
        margin-left: 0;
        border-top-right-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
        
        &.withBorder {
            border-color: $input-border-color;
        }

        .input-group-text {
            border: none;
            background-color: transparent;
            padding: 0.2rem $input-btn-padding-y 0.2rem 0 0.5rem;
        }

        .input-group-button {
            border: none;
            background-color: transparent;

            button {
                height: 100%;
                color: $secondary-color-30;
            }
        }

        button {
            background-color: transparent;
            border: none;

            &.fa {
                color: $primary-color;
            }
        }
    }

    &.disabled {
        input,
        .input-group-append,
        .input-group-prepend {
            background: $input-disabled-bg;
            border-color: $input-disabled-bg;
        }

        input {
            padding-right: 0;
        }
    }
}

.form-control {
    position: relative;
    background-color: $white;
    border-color: $white;
    height: $form-control-height;

    &.form-control-border {
        border-color: $input-border-color;
        &:focus {
            border-color: $input-focus-border-color;
        }
    }

    &:disabled {
        border-color: $input-disabled-bg;
    }

    &.ng-invalid.ng-touched {
        border: 1px solid $red;
    }

    &[ngbDropdownToggle] {
        > fa-icon {
            display: flex;
        }
    }
}

/**Additional styles can be changes by bootstrap variables in _variables.scss!*/

.form-check {
    display: flex;
    align-items: center;

    .form-check-input {
        &[type="checkbox"] {
            width: 2rem;
            height: 2rem;
        }
        cursor: pointer;
    }

    .form-check-label {
        margin-left: 0.5rem;
        font-weight: normal;
        cursor: pointer;
        text-align: left;
        text-transform: none;
        text-overflow: ellipsis;
        overflow-x: hidden;
        width: 100%;

        &::before {
            border-style: solid;
            border-width: 1px;
            border-color: $secondary-color-30;
        }

        &.no-border {
            &::before {
                border: 0;
            }
        }

        .custom-control-input:disabled {
            & + .custom-control-label::before {
                background-color: $secondary-color;
            }
            & + .custom-control-label::after {
                filter: grayscale(1);
            }
        }
    }

    .form-check-label.ng-invalid:not(.ng-pristine) {
        & + .form-check-label::before {
            border: 1px solid $red;
        }
    }
}

textarea {
    border: solid $border-width $input-border-color;

    &:focus {
        border-color: $input-focus-border-color;
    }
}

.custom-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 1rem;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: $primary-color;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: $primary-color;
        cursor: pointer;
    }
}

.unit-input {
    width: 100%;
    position: relative;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .unit {
        position: absolute;
        right: 1.3rem;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
}

input[type="radio"].dropdown-radio:checked + label.dropdown-item {
    color: white;
    background-color: $primary-color;
}
