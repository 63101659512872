﻿@import "_variables";
#app-header {
    background-color: $primary-color;
    width: 100%;
    min-height: $header-height;

    .navbar {
        height: $header-height;
        padding: 0 1rem;

        .navbar-toggler {
            border: none;
        }

        .navbar-brand {
            height: 100%;
            background-position: center;
        }

        &.navbar-sidebar {
            .navbar-collapse {
                z-index: $zindex-side-navigation;
                overflow-y: auto;
                background-color: $background-color !important;
                position: fixed;
                display: flex;
                flex-direction: column;
                top: 0;
                right: 0;
                height: 100%;
                min-width: 300px;
                max-width: 300px;
                color: $black;
                box-shadow: 0 0.5em 2em rgba(0, 0, 0, 0.3);

                .close-sidebar {
                    color: $primary-color !important;
                }

                .navbar-nav {
                    width: 100%;

                    .nav-header {
                        padding: 1rem 1rem 0 1.5rem;
                    }

                    &.navbar-bottom {
                        margin-top: auto;
                    }

                    .nav-item {
                        background-color: $white;
                        padding: 0.5rem 1rem;
                        margin: 0 0.5rem;

                        &.nav-text {
                            background-color: transparent;
                            margin: 0.5rem 0 0 0;
                            font-size: 11px;
                        }

                        &.split-item {
                            display: flex;
                            background-color: transparent;
                            align-items: center;
                            padding: 0;
                            justify-content: center;

                            .nav-link {
                                padding: 1rem;
                                display: inline-block;
                                text-align: center;
                                width: 100%;
                                background-color: $secondary-color;

                                &:not(:last-child) {
                                    border-right: 1px solid $secondary-color-30;
                                }

                                &::after {
                                    content: "" !important;
                                }
                            }
                        }

                        &.nav-border-bottom {
                            &:not(:last-child) {
                                border-bottom: 1px solid $secondary-color-30;
                            }
                        }

                        .nav-link {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .icon {
                                width: 2.5rem;
                            }
                        }

                        &.active {
                            .nav-link {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
