@import '_variables.scss';
@import '_variables_UFS.scss';
@import '_assets.scss';
@import 'bootstrap/scss/bootstrap';
@import '@angular/cdk/overlay-prebuilt.css';

@import 'mixins';
@import 'alerts';
@import 'spinner';
@import 'buttons';
@import 'nav';
@import 'forms';
@import 'tiles';
@import 'loginRegister';
@import 'cards';
@import 'dropdowns';
@import 'tables';
@import 'dialog';
@import 'carousel';
@import 'productChooser';
@import 'toggleSwitch';
@import 'modal';
@import 'tabs';
@import 'inlineEditor';
@import 'overlay';
@import 'range';
@import 'banner';
@import 'toasts';

html {
    height: 100%;

    body {
        overflow-x: hidden;
        min-height: 100%;
        position: relative;
        background-color: $background-color;
    }
}

body {
    * {
        &:not(.fa):not(.k-icon):not(.mce-ico):not(.oi) {
            font-family: sans-serif;
        }
    }

    &.font-default {
        * {
            &:not(.fa):not(.k-icon):not(.mce-ico):not(.oi) {
                font-family: NimbusSansL, ArialUnicode, sans-serif !important;
            }
        }
    }

    &.font-cyrillic {
        * {
            &:not(.fa):not(.k-icon):not(.mce-ico):not(.oi) {
                font-family: NimbusSansL, ArialUnicode, sans-serif !important;
            }
        }
    }

    .pac-container {
        z-index: 1100;
    }
}

a {
    color: $secondary-color-100;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: $secondary-color-85;

        &.catalogLink {
            color: $primary-color;
        }
    }
}

main {
    padding-top: $header-height;
    min-height: calc(100vh - #{$header-height});
}

body:has(.elbridge-banner) {
    main {
        padding-top: $header-with-elbridge-banner-height;
    }
}

body:has(.module-action-bar) {
    main {
        @include media-breakpoint-up(lg) {
            padding-left: $module-action-bar-width;
        }

        @include media-breakpoint-down(lg) {
            padding-top: calc($header-height + $module-action-bar-width-mobile);
        }
    }
}

.container-catalog {
    width: 100%;
    padding: 0 2rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 1500px;

    @include media-breakpoint-down(md) {
        padding: 0 0.5rem;
    }
}

.legacy {
    min-height: 100vh;
}

body:has(#in-app-navigation) {
    .legacy {
        padding-bottom: $in-app-navigation-height;
    }
}

section {
    &:not(.no-padding) {
        padding-bottom: 5rem;
    }

    margin-bottom: 1rem;
}

header {
    display: flex;
    flex-wrap: nowrap;

    &:not(.small-header) {
        margin: 3rem 0;

        @include media-breakpoint-down(lg) {
            margin: 1rem 0 2rem 0;
        }
    }
}

h2 {
    margin-bottom: 0;
}

@include media-breakpoint-down(lg) {
    h1,
    .h2 {
        font-size: $h1-mobile;
    }

    h2,
    .h2 {
        font-size: $h2-mobile;
    }

    h3,
    .h3 {
        font-size: $h3-mobile;
    }

    h4,
    .h4 {
        font-size: $h3-mobile;
    }
}

.urlhover {
    a {
        &:hover {
            color: $primary-color !important;
        }
    }
}

a {
    &.urlhover {
        &:hover {
            color: $primary-color !important;
        }
    }
}

.imageUpload {
    input[type='file'] {
        position: absolute;
        opacity: 0;
        z-index: 0;
        left: 0;
        max-width: 100%;
        display: block;
    }

    label {
        margin: 1rem 0;
        display: inline-flex;
    }
}

.pointer {
    cursor: pointer;
}

.overflow-y-auto {
    overflow-y: auto;
}

.visually-hidden {
    /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

.pos-absolute {
    position: absolute;
}

.pos-relative {
    position: relative;
}

.after-none {
    &::after {
        display: none;
    }
}

.before-none {
    &::before {
        display: none;
    }
}

.no-pseudo {
    @extend .after-none;
    @extend .before-none;
}

.text-normal {
    text-transform: none !important;
}

.text-ellipses {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.webkit-scrollbar {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-height;
        scroll-behavior: smooth;
        -webkit-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $secondary-color-10;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $scrollbar-color;
        -webkit-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbar-hover-color;
    }
}

.img-fluid,
.img-thumbnail {
    min-width: 1px; // IE11 Hack for flexbox img bug
    min-height: 1px; // ^^
    border: none !important;
}

.img-fluid-cover {
    height: 100%;
    object-fit: cover;
    max-width: 100%;
}

.img-aspectratio {
    height: auto;
    max-height: 100% !important;
}

.img-product {
    max-width: 3rem;
    max-height: 3rem;
    object-fit: contain;
}

.separator-bottom {
    border-bottom: 1px solid $secondary-color-10;
}

.separator-bottom-30 {
    border-bottom: 1px solid $secondary-color-30;
}

.max-w-100 {
    max-width: 100%;
}

.static-info-box {
    display: block;
    background-color: $alert-info-background-color;
    font-size: $custom-alert-font-size;
    line-height: $custom-alert-line-height;

    fa-icon {
        color: $alert-info-text-color;
    }

    p {
        font-size: 1rem;
        margin-bottom: 0;
    }
}

.bg-grey {
    background-color: $background-color !important;
}

.bg-white {
    background-color: $white !important;
}

.bg-blue {
    background-color: $obo-blue !important;
}

.filters {
    .filters-toggle-container{
        .dropdown-filter, shd-free-text-filter-input, shd-checkbox-filter {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .checkbox-filters {
            display: flex;
            align-items: center;
            height: 100%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            overflow: auto;
            white-space: nowrap;

            button.form-control {
                margin-bottom: .5rem;
                display: inline-block;
                border: 1px solid $white;

                .badge {
                    background-color: $primary-color;
                }

                &.active {
                    border: 1px solid $primary-color;
                }

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }

            .checkbox-filters {
                display: inline-block;
            }
        }
    }

    .filters-sidebar-container {
        button.form-control {
            &:not(:last-child) {
                margin-right: 1rem;
            }

            &.active {
                outline: 1px solid $primary-color;
            }
        }
    }
}

.filter-container {
    min-height: 95%;
    height: $sidebar-content-height;
    overflow-y: auto;
    overflow-x: hidden;
}

.product-filters {
    margin-bottom: 2rem;

    .dropdown-filter {
        margin-bottom: 1rem;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.hide-scrollbar {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.text-black {
    color: #000;
}
