@import "@angular/cdk/overlay-prebuilt.css";
@import "_mixins";

.overlay-container {
  background-color: $white;
  flex-direction: column;
  border-radius: 20px;

  > form {
    display: flex;
    width: 100%;
    overflow-x: hidden !important;
    height: 100%;
    flex-direction: column;
  }

  &.overlay-container-height {
    max-height: 300px;

    @include media-breakpoint-down(xl) {
      max-height: unset;
    }
  }
}

.cdk-overlay-container {
  z-index: $zindex-modal + 1;

  .cdk-global-overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    outline: 0;
    overflow-y: auto;
  }
}
