﻿@use 'sass:math';
@import 'bootstrap/scss/_functions';

/**brand colors*/
$primary-color: #f39b00;
$secondary-color: #fff;
$secondary-color-10: darken($secondary-color, 10);
$secondary-color-30: darken($secondary-color, 30);
$secondary-color-50: darken($secondary-color, 50);
$secondary-color-85: darken($secondary-color, 85);
$secondary-color-100: darken($secondary-color, 100);
$obo-blue-dark: #41525d;
$obo-blue-light: #c5d9de;
$obo-blue-lighter: #e4ebed;
$obo-blue: #7694a4;
$obo-blue-flag: #2678b2;
$obo-pastel-yellow: #fffceb;
$obo-brown: darken($primary-color, 20);
$background-color: #f4f7f8;
$in-app-navigation-background-color: #7694a3;
$active-state-color: $primary-color;

$btn-primary-color: $primary-color;
$btn-hover-color: #f8cd7f;
$btn-down-color: $btn-hover-color;
$btn-disabled-color: #acb1b5;

$btn-text-primary-color: $secondary-color;
$btn-text-hover-color: $secondary-color;
$btn-text-down-color: $secondary-color;
$btn-text-disabled-color: $secondary-color;

$btn-link-down: rgba($primary-color, 0.9);

$secondary-light-grey: #e1e1e1;
$secondary-dark-grey: #ccc;

// pv configurator map colors
$pv-load-map-zone-1: #d9d9d9;
$pv-load-map-zone-2: #b3b3b3;
$pv-load-map-zone-3: #666666;
$pv-load-map-zone-4: #333333;

$border-weight: 1px;

$h1-mobile: 1.7rem;
$h2-mobile: 1.3rem;
$h3-mobile: 1rem;

$transparency-level: 0.25;

//  Custom grid breakpoints
//
//  added xl for the purpose of shrinking the ufs content if sidebar is opened on devices with smaller widths than full hd
//  added 3xl breakpoint

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1870px,
    xxxl: 1921px
);
$sidebar-width: 380px;
$sidebar-tab-width: 60px;
$sidebar-content-height: 65vh;
$sidebar-title-height: 80px;

// Bootstrap Variables
//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
    100: $gray-100,
    200: $gray-200,
    300: $gray-300,
    400: $gray-400,
    500: $gray-500,
    600: $gray-600,
    700: $gray-700,
    800: $gray-800,
    900: $gray-900
) !default;

$blue: #2578b1 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9232b !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #58b146 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

//colors for distributions of overvoltage module
$distribution-colors: (
    'mainDistribution': $primary-color,
    'subDistribution': $obo-brown,
    'terminal': $obo-blue,
    'buildingEntry': $obo-blue-dark
);

$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800
) !default;

$theme-colors: (
    primary: $primary-color,
    secondary: $secondary-color-100,
    success: $green,
    info: $blue,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: true !default;
$enable-shadows: false;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: (
        $spacer * 0.25
    ),
    2: (
        $spacer * 0.5
    ),
    3: $spacer,
    4: (
        $spacer * 1.5
    ),
    5: (
        $spacer * 3
    )
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
    10: 10%,
    25: 25%,
    50: 50%,
    65: 65%,
    75: 75%,
    100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color: map-get($theme-colors, 'primary') !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1170px
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 0.5px !default;
$border-color: $gray-200 !default;

$border-radius: 8px !default;
$border-radius-lg: 8px !default;
$border-radius-sm: 8px !default;

$component-active-color: $white !default;
$component-active-bg: map-get($theme-colors, 'primary') !default;

$caret-width: 0.3em !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;

$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: 2.571rem !default;
$h2-font-size: 1.714rem !default;
$h3-font-size: 1.286rem !default;
$h4-font-size: 1.143rem !default;
$h5-font-size: 1.05rem !default;
$h6-font-size: 1rem !default;

$accordion-title-line-height: $h2-font-size * 1.2;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: 1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-200 !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-inverse-bg: $gray-900 !default;
$table-inverse-accent-bg: rgba($white, 0.05) !default;
$table-inverse-hover-bg: rgba($white, 0.075) !default;
$table-inverse-border-color: lighten($gray-900, 7.5%) !default;
$table-inverse-color: $body-bg !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y: 0.7rem !default;
$input-btn-padding-x: 1.3rem !default;
$input-btn-line-height: 1.5 !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba(map-get($theme-colors, 'primary'), 0.25) !default;
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm: 0.7rem !default;
$input-btn-padding-x-sm: 1.3rem !default;
$input-btn-line-height-sm: 1.5 !default;

$input-btn-padding-y-lg: 0.7rem !default;
$input-btn-padding-x-lg: 1.3rem !default;
$input-btn-line-height-lg: 1.5 !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-success-box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.3) !important;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

//Custom icon buttons
$button-with-circle-size: 2.573rem;
$button-with-circle-margin: 0.3rem;
$button-with-circle-outer-size: $button-with-circle-size + 2 * $button-with-circle-margin;

//Custom button border-radius
$custom-btn-border-radius: 50px;

//Custom button sizes
$btn-navigation-max-size: 280px;

// Forms
$input-bg: $white !default;
$input-disabled-bg: #edf3f4 !default;

$input-color: $gray-700 !default;
$input-border-color: rgba($black, 0.15) !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: none;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: $active-state-color !default;
$input-focus-box-shadow: $input-box-shadow, $btn-focus-box-shadow !default;
$input-focus-color: $input-color !default;

$input-placeholder-color: $gray-600 !default;

$input-height-border: $input-btn-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !default;

$form-text-margin-top: 0.25rem !default;
$form-check-input-border-radius: 0rem !default;
$form-check-input-width: 1.5em !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-control-gutter: 1rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size: 1.5rem !default;
$custom-control-indicator-bg: $secondary-color !default;
$custom-control-indicator-bg-size: 70% 70% !default;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-border-color: map-get($theme-colors, 'primary');

$custom-control-indicator-disabled-bg: $gray-200 !default;
$form-check-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: map-get($theme-colors, 'primary');
$custom-control-indicator-checked-bg: $secondary-color;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: none;

$custom-control-indicator-active-color: map-get($theme-colors, 'primary') !default;
$custom-control-indicator-active-bg: lighten(map-get($theme-colors, 'primary'), 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjx0aXRsZT5jaGVja21hcms8L3RpdGxlPjxwYXRoIGZpbGw9IiNmMzljMDAiIGQ9Ik0wLjYsMjIuNyAxMiwzMC42IDEyLDMwLjYgMTYuMiwzMy40IDM1LjQsNS40IDMxLjMsMi42IDE0LjksMjYuNCAzLjQsMTguNSB6Ii8+PC9zdmc+');

$custom-checkbox-indicator-indeterminate-bg: map-get($theme-colors, 'primary') !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"),
    '#',
    '%23'
) !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$primary-color}'/%3E%3C/svg%3E"),
    '#',
    '%23'
) !default;

$custom-select-padding-y: 0.375rem !default;
$custom-select-padding-x: 0.75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $white !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: map-get($theme-colors, 'primary');
$custom-select-indicator: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyIDMyIj4KPHRpdGxlPmFycm93X3NtYWxsPC90aXRsZT4KPHBhdGggZmlsbD0iI2YzOWMwMCIgZD0iTTUuODQgMjcuNjhsNC4yNCA0LjI0IDE2LTE1LjkyLTE2LTE2LTQuMjQgNC4zMiAxMS42OCAxMS42OHoiPjwvcGF0aD4KPC9zdmc+Cg==');
$custom-select-border-width: $input-btn-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-box-shadow: none;

$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-font-size-lg: 125% !default;
$custom-select-height-lg: $input-height-lg !default;

$custom-file-height: $input-height !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-btn-focus-box-shadow !default;

$custom-file-padding-y: $input-btn-padding-y !default;
$custom-file-padding-x: $input-btn-padding-x !default;
$custom-file-line-height: $input-btn-line-height !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-btn-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
    en: 'Browse'
) !default;

// Form validation
$form-feedback-valid-color: map-get($theme-colors, 'success') !default;
$form-feedback-invalid-color: map-get($theme-colors, 'danger') !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.3rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: $gray-200 !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.25rem !default;
$dropdown-item-padding-x: 1.5rem !default;

$dropdown-header-color: $gray-600 !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$z-index-overlay: 200;
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-alert: 1080 !default;
$zindex-side-navigation: 1085 !default;
// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: #ddd !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: #ddd !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// Navbar

$navbar-padding-y: math.div($spacer, 2) !default;
$navbar-padding-x: $spacer !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-padding-y: math.div(($navbar-brand-height - $nav-link-height), 2) !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, 1) !default;
$navbar-dark-hover-color: rgba($black, 1) !default;
$navbar-dark-active-color: rgba($white, 1) !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
    '#',
    '%23'
) !default;
$navbar-dark-toggler-border-color: rgba($primary-color, 0.1) !default;

// Pagination

$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: #ddd !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: #ddd !default;

$pagination-active-color: $white !default;
$pagination-active-bg: map-get($theme-colors, 'primary') !default;
$pagination-active-border-color: map-get($theme-colors, 'primary') !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: #ddd !default;

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin: math.div($grid-gutter-width, 2) !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $obo-blue-dark !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 5px !default;
$tooltip-padding-x: 15px !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 10px !default;
$tooltip-arrow-height: 6px !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Popovers

$popover-inner-padding: 1px !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 8px !default;
$popover-header-padding-x: 14px !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: 9px !default;
$popover-body-padding-x: 14px !default;

$popover-arrow-width: 10px !default;
$popover-arrow-height: 5px !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-width: ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Badges

$badge-color: $white !default;
$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.4em !default;

$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: 10px !default;
$modal-dialog-margin-y-sm-up: 30px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $background-color !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 15px !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.5rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 0.2rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

//Custom Alert Settings
$alert-success-text-color: #61a146;
$alert-success-background-color: #e9f5e3;

$alert-danger-text-color: #d8232a;
$alert-danger-background-color: #f7d3d5;

$alert-info-text-color: #2678b2;
$alert-info-background-color: #c7ddeb;

$alert-warning-text-color: #f5cd00;
$alert-warning-background-color: #ffffe7;

$custom-alert-font-size: 1.2rem;
$custom-alert-line-height: 1.8rem;

// Progress bars
$progress-height: 1rem !default;
$progress-font-size: 0.75rem !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius !default;
$progress-bar-color: $white !default;
$progress-bar-bg: map-get($theme-colors, 'primary') !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// List group

$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-700 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Image thumbnails

$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: #ddd !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;
$thumbnail-transition: all 0.2s ease-in-out !default;

// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-600 !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"),
    '#',
    '%23'
) !default;
$carousel-control-next-icon-bg: str-replace(
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"),
    '#',
    '%23'
) !default;

$carousel-transition: transform 0.6s ease !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

// Code

$code-font-size: 90% !default;
$code-padding-y: 0.2rem !default;
$code-padding-x: 0.4rem !default;
$code-color: #bd4147 !default;
$code-bg: $gray-100 !default;

$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;

// Custom Variables

$sidebar-margin-offset: 20px;

// Custom z-indices
$sidebar-z-index: $zindex-modal + 2;
$tour-z-index: $sidebar-z-index + 1;
$in-app-navigation-z-index: $zindex-sticky + 1;
$fab-z-index: $zindex-sticky + 2;
$module-action-bar-z-index: $zindex-sticky + 2;

// Custom Heights
$header-height: 50px;
$in-app-navigation-height: 80px;
$elbridge-banner-heigth: 70px;
$header-with-elbridge-banner-height: calc(#{$header-height} + #{$elbridge-banner-heigth});

// Custom width
$module-action-bar-width: 80px;
$module-action-bar-width-mobile: $module-action-bar-width - 20px;

// Custom Scrollbar Variables
$scrollbar-width: 0.5rem;
$scrollbar-height: 0.5rem; // for horizontal use
$scrollbar-color: $primary-color;
$scrollbar-hover-color: $btn-hover-color;

// Dashboard
$dashboard-border-radius: 5px;
