﻿$spinner-size: 32px;
$spinner-size-progress: 64px;

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.spinner {
  z-index: $zindex-popover;
  text-align: center;
  transition: all .2s linear;

  &.visible {
    display: block;
  }

  .inner {
    width: $spinner-size;
    height: $spinner-size;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    line-height: $spinner-size;

    .progress-text {
      color: black;
      opacity: 0;
      width: $spinner-size-progress;
    }

    &:before {
      border-radius: 50%;
      content: "";
      width: $spinner-size;
      height: $spinner-size;
      display: inline-block;
      box-sizing: border-box;
      border-top: solid 6px $secondary-color-10;
      border-right: solid 6px $secondary-color-10;
      border-bottom: solid 6px $secondary-color-10;
      border-left: solid 6px $secondary-color-10;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      border-radius: 50%;
      content: " ";
      width: $spinner-size;
      height: $spinner-size;
      display: inline-block;
      box-sizing: border-box;
      border-top: solid 6px $primary-color;
      border-right: solid 6px transparent;
      border-bottom: solid 6px transparent;
      border-left: solid 6px transparent;
      position: absolute;
      top: 0;
      left: 0;
      animation: cp-round-animate 1s ease-in-out infinite;
    }
  }

  &.spinner-progress {
    transform: scale(1.3);

    .inner {
      line-height: $spinner-size-progress;
      width: $spinner-size-progress;
      height: $spinner-size-progress;

      &:before, &:after {
        width: $spinner-size-progress;
        height: $spinner-size-progress;
      }

      .progress-text {
        opacity: 1;
      }
    }
  }
}

.loading-layer {
  position: fixed;
  background: rgba(#eee, 0.6);
  width: 100%;
  height: 100%;
  z-index: $zindex-modal-backdrop;
  top: 0;
  left: 0;
}

@keyframes cp-round-animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
